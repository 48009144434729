import React from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { landingTheme } from './theme';
import NextLink from 'next/link';

export const LandingHeader = () => {
  const background = landingTheme.primaryOrange;

  return (
    <Box width="100%" h="32px" background={background} px={8}>
      <Flex
        maxWidth="1174px"
        margin="auto"
        lineHeight="30px"
        fontWeight={500}
        fontSize={['sm', '16px']}
      >
        <Box
          color="scheme.textPrimaryInverse"
          px={2}
          borderBottom={`2px solid ${landingTheme.primaryYellow}`}
        >
          <NextLink href={'/experts'}>For Experts</NextLink>
        </Box>
        <Box px={2}>
          <Link
            target="_blank"
            href={'https://www.withremark.com/'}
            textDecoration="none !important"
            color="scheme.textPrimaryInverse"
          >
            For Brands
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

LandingHeader.propTypes = {};
