import { Box, Center, Heading } from '@chakra-ui/layout';
import { ButtonNextLink } from '@outdoorly/button';
import { fadeInVariant, landingTheme } from './theme';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

export const LandingCTA = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <Box
      backgroundColor={landingTheme.primaryOrange}
      py={['30px', '30px', '80px']}
      px={[5, 5, 10]}
    >
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={fadeInVariant}
      >
        <Box maxW="730px" margin="auto">
          <Heading
            as="h2"
            fontFamily="aesthet-nova, serif"
            fontWeight={900}
            fontSize={['3xl', '3xl', '3xl', '48px']}
            textAlign="center"
            color="scheme.textPrimaryInverse"
            mb={['20px', '20px', '40px']}
          >
            {'You deserve gear for being who you are.'}
          </Heading>
          <Center>
            <ButtonNextLink
              route={'/signup'}
              color={landingTheme.primaryOrange}
              variant="solid"
              backgroundColor="white"
              _hover={{ opacity: 0.8 }}
              _active={{ opacity: 0.6 }}
              size="lg"
              width="auto"
            >
              {"Let's get going"}
            </ButtonNextLink>
          </Center>
        </Box>
      </motion.div>
    </Box>
  );
};

LandingCTA.propTypes = {};
