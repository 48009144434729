import { Box, Heading, Text } from '@chakra-ui/layout';
import { landingTheme } from './theme';
import expertHero from '../../public/images/landing/expert-hero.png';
import NextImage from 'next/image';
import { ButtonNextLink } from '@outdoorly/button';
import { useBreakpoint } from '@chakra-ui/media-query';
import { motion } from 'framer-motion';

export const LandingHero = () => {
  const breakpoint = useBreakpoint();
  return (
    <Box
      w="100%"
      bg={landingTheme.primaryYellow}
      pos="relative"
      py={[10, 10, 20]}
      overflow="hidden"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <Box
          pos="absolute"
          h="100%"
          w="100%"
          top={0}
          right={['-360px', '-360px', '-60px', '50px']}
        >
          <NextImage
            layout="fill"
            objectFit={'contain'}
            src={expertHero}
            objectPosition="right"
            hidden={breakpoint === 'sm' || breakpoint === 'base'}
          />
        </Box>
      </motion.div>

      <Box
        maxW={['1176px', '1176px', '400px', '1176px']}
        margin={['auto', 'auto', '0 4px', 'auto']}
        px={8}
      >
        <Box mb={10} maxW={'540px'} mt={['30px', '30px', '30px']}>
          <motion.div
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.6, duration: 1 }}
          >
            <Heading
              fontSize={['56px', '72px']}
              fontFamily="aesthet-nova, serif"
              fontWeight={900}
              my={'32px'}
              as="h1"
              pos="relative"
            >
              <>
                Get rewarded for your{' '}
                <span style={{ color: landingTheme.primaryOrange }}>
                  expertise
                </span>
                .
              </>
            </Heading>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.8, duration: 1 }}
          >
            <Text
              fontSize="18px"
              color="scheme.textSecondary"
              lineHeight="32px"
              pos="relative"
            >
              {
                "As an outdoor expert, your knowledge and skills are invaluable. That's why we've created Outdoorly, an exclusive marketplace, specifically for you. Here, you'll find discounts on the gear you need to excel in your field, as well as opportunities to be recognized and rewarded for your expertise. Join now to start reaping the benefits of being a part of our community."
              }
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <ButtonNextLink
              variant="solid"
              background={landingTheme.primaryOrange}
              color="scheme.textPrimaryInverse"
              _hover={{ opacity: 0.8 }}
              _active={{ opacity: 0.6 }}
              mt={10}
              route="/signup"
              size="lg"
              width="auto"
            >
              {'Get started'}
            </ButtonNextLink>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

LandingHero.propTypes = {};
