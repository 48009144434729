import PropTypes from 'prop-types';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import quotationMark from '../../public/images/landing/quotation-mark.svg';
import mammutLogo from '../../public/images/landing/mammut-logo.png';
import klymitLogo from '../../public/images/landing/klymit-logo.png';
import libertyLogo from '../../public/images/landing/liberty-logo.png';
import NextImage from 'next/image';
import { Card } from '@outdoorly/card';
import { Text } from '@outdoorly/text';
import { fadeInVerticalVariant, landingTheme } from './theme';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const QuoteCard = ({ body, name, title, leftMargin, rightMargin, logo }) => (
  <Card
    shadow="lg"
    borderRadius="3xl"
    pos="relative"
    mb={'16px'}
    ml={[0, 0, leftMargin ? '95px' : 0]}
    mr={[0, 0, rightMargin ? '95px' : 0]}
  >
    <Box pos="absolute" w="16px" h="14px" left="32px" top="32px">
      <NextImage src={quotationMark} objectFit="contain" layout="fill" />
    </Box>
    <Box ml={8} mt={2}>
      <Text size="lg" style={{ lineHeight: '32px' }}>
        {body}
      </Text>
      <Text fontWeight={700} size="lg" mb={4} mt={8}>
        {name}
      </Text>
      {title && (
        <Text size="md" mt={3} color="scheme.textSecondary">
          {title}
        </Text>
      )}
      {logo && (
        <Box h={'50px'} position="relative" w="full" maxW="85px" ml="1px">
          <NextImage src={logo} layout="fill" objectFit="contain" />
        </Box>
      )}
    </Box>
  </Card>
);

QuoteCard.propTypes = {
  body: PropTypes.string,
  leftMargin: PropTypes.bool,
  name: PropTypes.string,
  rightMargin: PropTypes.bool,
  title: PropTypes.string,
  logo: PropTypes.string,
};

export const LandingQuote = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0 });
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <Box
      background={landingTheme.primaryYellow}
      h="100%"
      pos="relative"
      pb={'80px'}
    >
      <Flex
        maxW="990px"
        margin="auto"
        pos="relative"
        px={[15, 15, 15, 0]}
        flexDir={['column', 'column', 'row']}
        alignItems="center"
      >
        <Box
          pos="absolute"
          w="143px"
          h="120px"
          left="-85px"
          top="60px"
          opacity="0.1"
          display={['none', 'none', 'none', 'inherit']}
        >
          <NextImage src={quotationMark} objectFit="contain" layout="fill" />
        </Box>
        <Box flexBasis="50%" mr={[0, 0, '15px']}>
          <Heading
            as="h2"
            pt={[8, 8, '125px']}
            pb={[0, 0, 8]}
            fontFamily="aesthet-nova, serif"
            fontWeight={900}
            fontSize={['3xl', '3xl', '4xl', '5xl']}
            color="scheme.textPrimary"
            textAlign={['center', 'center', 'left']}
            mb={'16px'}
          >
            Trust the experts on our experts.
          </Heading>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              ...fadeInVerticalVariant,
              visible: {
                ...fadeInVerticalVariant.visible,
                transition: {
                  ...fadeInVerticalVariant.visible.transition,
                  delay: 0.1,
                },
              },
            }}
          >
            <QuoteCard
              body={
                'Whenever we have a new idea or challenge, the team at Outdoorly is always excited to dive in and bring it to life. Shopping for technical gear is never easy, and we greatly value the advice that pros provide to give our shoppers confidence in the products they’re selecting, and to ensure they are having the best time possible in the mountains.'
              }
              name={'David Russo'}
              title={'VP of Sales & Marketing, Mammut'}
              leftMargin
              logo={mammutLogo}
            />
          </motion.div>
        </Box>
        <Box flexBasis="50%" ml={[0, 0, '15px']} mt={[0, 0, '150px']}>
          <motion.div
            animate={controls}
            initial="hidden"
            variants={fadeInVerticalVariant}
          >
            <QuoteCard
              body={
                'Newcomers in the camping segment are highly influenced by professional insights and recommendations — they want to know what gear does the best at the best price. Our communication with Outdoorly here has been very fast and efficient, and their processes have led to a very painless operation as we navigated through order processing.'
              }
              name={'AJ Cutler'}
              title={'Digital Marketing Specialist, Klymit'}
              logo={klymitLogo}
            />
          </motion.div>
          <motion.div
            animate={controls}
            initial="hidden"
            variants={{
              ...fadeInVerticalVariant,
              visible: {
                ...fadeInVerticalVariant.visible,
                transition: {
                  ...fadeInVerticalVariant.visible.transition,
                  delay: 0.2,
                },
              },
            }}
          >
            <QuoteCard
              body={
                'Our customers crave expert advice when they’re shopping for gear. Outdoorly’s pros are unbiased and really know their stuff, and they’ve 100% effectively informed potential customers and helped guide them through the purchasing process.'
              }
              name={'James Satloff'}
              title={'Chairman, Liberty Skis'}
              rightMargin
              logo={libertyLogo}
            />
          </motion.div>
        </Box>
      </Flex>
    </Box>
  );
};
LandingQuote.propTypes = {};
