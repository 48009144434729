import { Box, Center, Flex, Heading } from '@chakra-ui/layout';
import { Text } from '@outdoorly/text';
import { ButtonNextLink } from '@outdoorly/button';
import expertCommunity1 from '../../public/images/landing/expert-community-1.png';
import expertCommunity2 from '../../public/images/landing/expert-community-2.png';
import expertCommunity3 from '../../public/images/landing/expert-community-3.png';
import expertCommunity4 from '../../public/images/landing/expert-community-4.png';
import NextImage from 'next/image';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { fadeInVariant, landingTheme } from './theme';

export const ExpertCommunity = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <Box w="100%" background={landingTheme.primaryYellow} py="64px">
      <Flex
        flexDir={['column-reverse', 'column-reverse', 'row']}
        maxW="1158px"
        margin="auto"
        p={'0 1rem 1rem 1rem'}
      >
        <Box
          flexBasis={['50%', '50%', '60%', '50%']}
          mr={[0, 0, '50px', '78px']}
        >
          <Flex
            h="50%"
            m={['auto', 'auto', 0]}
            maxW={['470px', '470px', 'none']}
            alignItems="flex-end"
          >
            <Box
              h="100%"
              w="100%"
              pos="relative"
              mr={[2, 2, 4]}
              minH={['200px', '300px', 0]}
              __css={{ '& img': { borderRadius: ['16px', '16px', '36px'] } }}
            >
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={fadeInVariant}
              >
                <NextImage
                  objectFit="cover"
                  layout="fill"
                  src={expertCommunity1}
                />
              </motion.div>
            </Box>
            <Box
              h={['100%', '100%', '65%']}
              w="100%"
              pos="relative"
              minH={['200px', '300px', 0]}
              __css={{ '& img': { borderRadius: ['16px', '16px', '36px'] } }}
            >
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                  ...fadeInVariant,
                  visible: {
                    ...fadeInVariant.visible,
                    transition: {
                      ...fadeInVariant.visible.transition,
                      delay: 0.1,
                    },
                  },
                }}
              >
                <NextImage
                  objectFit="cover"
                  layout="fill"
                  src={expertCommunity2}
                />
              </motion.div>
            </Box>
          </Flex>
          <Flex
            h="50%"
            pos="relative"
            mx={['auto', 'auto', '1rem 0 0 0']}
            mt={[2, 2, 4]}
            maxW={['470px', '470px', 'none']}
          >
            <Box
              h={['100%', '100%', '80%']}
              w={['100%', '100%', '50%']}
              pos="relative"
              mr={[2, 2, 4]}
              minH={['200px', '300px', 0]}
              __css={{ '& img': { borderRadius: ['16px', '16px', '36px'] } }}
            >
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                  ...fadeInVariant,
                  visible: {
                    ...fadeInVariant.visible,
                    transition: {
                      ...fadeInVariant.visible.transition,
                      delay: 0.2,
                    },
                  },
                }}
              >
                <NextImage
                  objectFit="cover"
                  layout="fill"
                  src={expertCommunity3}
                />
              </motion.div>
            </Box>
            <Box
              h="100%"
              w="100%"
              pos="relative"
              minH={['200px', '300px', 0]}
              __css={{ '& img': { borderRadius: ['16px', '16px', '36px'] } }}
            >
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                  ...fadeInVariant,
                  visible: {
                    ...fadeInVariant.visible,
                    transition: {
                      ...fadeInVariant.visible.transition,
                      delay: 0.3,
                    },
                  },
                }}
              >
                <NextImage
                  objectFit="cover"
                  layout="fill"
                  objectPosition="left"
                  src={expertCommunity4}
                />
              </motion.div>
            </Box>
          </Flex>
        </Box>
        <Box flexBasis="50%" py={[0, 0, '80px', '150px']}>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={fadeInVariant}
          >
            <Heading
              as="h2"
              py={[0, 0, 8]}
              fontFamily="aesthet-nova, serif"
              fontWeight={900}
              color="black"
              fontSize={['3xl', '3xl', '48px']}
              textAlign={['center', 'center', 'left']}
              maxW="440px"
              m={['auto', 'auto', 'auto', 0]}
            >
              We believe in a genuine community.
            </Heading>
            <Text
              size="lg"
              color="scheme.textSecondary"
              textAlign={['center', 'center', 'left']}
              mt={[4, 4, 0]}
              mb="40px"
            >
              Make the most out of being a pro by enjoying the outdoors the way
              it was meant to be enjoyed. Being a good steward of these
              discounts is one of our top community values.
              <br /> <br />
              Want to join the community?
            </Text>
            <Center justifyContent={['center', 'center', 'flex-start']}>
              <ButtonNextLink
                route="/signup"
                variant="solid"
                backgroundColor={landingTheme.primaryOrange}
                color="white"
                _hover={{ opacity: 0.8 }}
                _active={{ opacity: 0.6 }}
                size="lg"
                width="auto"
                mb={'20px'}
              >
                Get Started
              </ButtonNextLink>
            </Center>
          </motion.div>
        </Box>
      </Flex>
    </Box>
  );
};
