export const landingTheme = {
  primaryBlue: '#265464',
  primaryYellow: '#FDFBED',
  buttonBlue: '#002664',
  primaryPink: '#FFF0F0',
  secondaryBlue: '#F1F8F5',
  secondaryYellow: '#FAEFCC',
  primaryOrange: '#FAA849',
  buttonOrange: '#FAEFCC',
  primaryGreen: '#6D7451',
  circleBlue: '#8CB1C5',
};

export const fadeInVariant = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

export const fadeInVerticalVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: '-20px' },
};
