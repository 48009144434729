import PropTypes from 'prop-types';
import {
  Box,
  Center,
  WrapItem,
  Heading,
  HStack,
  Wrap,
} from '@chakra-ui/layout';
import { ButtonNextLink } from '@outdoorly/button';
import { Text } from '@outdoorly/text';
import { fadeInVariant, landingTheme } from './theme';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { ArrowRightIcon } from '@outdoorly/icons';
import NextImage from 'next/image';
import { useBreakpoint } from '@chakra-ui/media-query';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import expertHero1 from '../../public/images/landing/expert-hero-1.png';
import expertHero2 from '../../public/images/landing/expert-hero-2.jpeg';
import expertHero3 from '../../public/images/landing/expert-hero-3.jpg';
import expertHero4 from '../../public/images/landing/expert-hero-4.jpg';
import expertHero5 from '../../public/images/landing/expert-hero-5.jpg';
import expertHero6 from '../../public/images/landing/expert-hero-6.jpeg';
import expertProfile1 from '../../public/images/landing/expert-profile-1.png';
import expertProfile2 from '../../public/images/landing/expert-profile-2.jpeg';
import expertProfile3 from '../../public/images/landing/expert-profile-3.jpg';
import expertProfile4 from '../../public/images/landing/expert-profile-4.jpg';
import expertProfile5 from '../../public/images/landing/expert-profile-5.jpg';
import expertProfile6 from '../../public/images/landing/expert-profile-6.jpeg';
import SwiperCore, { Navigation, Thumbs } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

const ExpertCard = ({ image, name, title, summary, profileImage, swiping }) => {
  return (
    <Box
      as="li"
      borderRadius="36px"
      w="350px"
      pos="relative"
      h="100%"
      m={swiping ? '30px auto' : 0}
      background={'white'}
      __css={{ '& div img': { borderRadius: '36px 36px 0 0' } }}
    >
      <NextImage
        layout="fixed"
        width="350px"
        height="198px"
        src={image}
        objectFit="cover"
        placeholder="blur"
      />
      <Box p="24px">
        <HStack
          alignItems="center"
          mb={'24px'}
          __css={{ '& div': { borderRadius: '50%' } }}
        >
          <NextImage
            layout="fixed"
            width="52px"
            height="52px"
            src={profileImage}
            objectFit="cover"
          />
          <Box ml="16px">
            <Text fontWeight={500} size="xl" mb={3}>
              {name}
            </Text>
            <Text size="md" mt={3}>
              {title}
            </Text>
          </Box>
        </HStack>
        <Text size="lg">{summary}</Text>
      </Box>
    </Box>
  );
};

ExpertCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  profileImage: PropTypes.string,
  summary: PropTypes.string,
  swiping: PropTypes.bool,
  title: PropTypes.string,
};

export const ExpertList = () => {
  const experts = [
    {
      image: expertHero1,
      name: 'Tyler M.',
      title: 'Climbing Guide, 8 years',
      summary:
        "Tyler cuts his teeth on New England rock, frequenting Acadia, Rumney, and the White Mountains. Recently he's expanded westward to Boulder.",
      profileImage: expertProfile1,
    },
    {
      image: expertHero2,
      name: 'Kieren B.',
      title: 'Nonprofit founder, 5 years',
      summary:
        'Kieren founded the nonprofit Lady Alliance to shape new leaders in the outdoors through scholarships and community building.',
      profileImage: expertProfile2,
    },
    {
      image: expertHero3,
      name: 'Alex C.',
      title: 'Ski instructor, 6 years',
      summary:
        'Alex is a ski instructor in Jackson in the winter and a hiking guide in the summer. He really struggles to leave the mountains. Like, really.',
      profileImage: expertProfile3,
    },
    {
      image: expertHero4,
      name: 'Caroline M.',
      title: 'Surfing instructor, 4 years',
      summary:
        'Raised on the eastern coast, Caroline was raised on the rough ocean waves. She loves to share her surfing passion with eager learners.',
      profileImage: expertProfile4,
    },
    {
      image: expertHero5,
      name: 'Tom K.',
      title: 'Ski instructor, 7 years',
      summary:
        'Tom is an avid skier, climber, & surfer hailing from Mount Desert Island, Maine. Tom feels at home in the natural beauty of his home state.',
      profileImage: expertProfile5,
    },
    {
      image: expertHero6,
      name: 'Andrew Y.',
      title: 'Climbers of Color, 4 years',
      summary:
        'Andrew enjoys mountaineering, alpine climbing, and skiing. He joined Climbers of Color to help promote DE&I in mountaineering & climbing.',
      profileImage: expertProfile6,
    },
  ];
  const breakpoint = useBreakpoint();
  const isSmallScreen = ['base', 'sm'].includes(breakpoint);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Box py={10} background={landingTheme.primaryOrange}>
      <Box m="auto" maxW="750px" px="5%">
        <Heading
          as="h2"
          py={4}
          fontFamily="aesthet-nova, serif"
          fontWeight={900}
          color="scheme.textPrimaryInverse"
          fontSize={['3xl', '3xl', '3xl', '48px']}
          mt={[4, 4, 4, 8]}
          textAlign="center"
        >
          A lifetime of expertise
        </Heading>
        <Text
          size="lg"
          color="scheme.textPrimaryInverse"
          textAlign="center"
          mb={10}
        >
          Our community of experts are knowledgeable, experienced, and friendly.
          Are you ready to be one of them?
        </Text>
      </Box>
      <Swiper
        loop={true}
        thumbs={{ swiper: thumbsSwiper }}
        className="expert-summary"
      >
        {experts.map((expert, index) => (
          <SwiperSlide key={`${expert.name} ${index}`}>
            <ExpertCard key={index} {...expert} swiping />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={!thumbsSwiper && setThumbsSwiper}
        slidesPerView={6}
        watchSlidesProgress={true}
        className="expert-thumbs"
        noSwiping
        threshold={99999}
      >
        {[...Array(experts.length)].map((u, i) => (
          <SwiperSlide key={i}>
            <Box height="4px" width="4px" cursor="pointer" />
          </SwiperSlide>
        ))}
      </Swiper>
      {!isSmallScreen && (
        <Wrap justify="center" mt={10} maxW="6xl" m="auto">
          {experts.map((expert, index) => (
            <WrapItem key={index} p={'8px'}>
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                  ...fadeInVariant,
                  visible: {
                    ...fadeInVariant.visible,
                    transition: {
                      ...fadeInVariant.visible.transition,
                      delay: index / 10,
                    },
                  },
                }}
              >
                <ExpertCard key={index} {...expert} />
              </motion.div>
            </WrapItem>
          ))}
        </Wrap>
      )}
      <Center>
        <ButtonNextLink
          variant="outline"
          color="scheme.textPrimaryInverse"
          rightIcon={<ArrowRightIcon color="white" />}
          mt={8}
          mb={12}
          route="/experts"
          size="lg"
          width="auto"
          _hover={{ opacity: 0.8 }}
          _active={{ opacity: 0.6 }}
        >
          Are you an expert?
        </ButtonNextLink>
      </Center>
    </Box>
  );
};
