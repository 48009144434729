import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Center,
  Grid,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextImage from 'next/image';
import Link from 'next/link';
import { Logo, LogoWordmarkRemarkCompany } from '@outdoorly/logo';
import { ButtonNextLink } from '@outdoorly/button';
import { LandingHeader } from './LandingHeader';
import { fadeInVariant, fadeInVerticalVariant, landingTheme } from './theme';
import { LandingHero } from './LandingHero';
import Head from 'next/head';
import { LandingMediaSummary } from './LandingMediaSummary';
import { ExpertList } from './ExpertList';
import { LandingQuote } from './LandingQuote';
import { LandingCTA } from './LandingCTA';
import { ExpertCommunity } from './ExpertCommunity';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MainHeader = () => (
  <Flex
    as="header"
    bg={'white'}
    py={6}
    px={8}
    justify="center"
    boxShadow="inset 0px -1px 0px gainsboro"
  >
    <Flex
      maxW={['full', null, null, '1110px']}
      flexGrow={1}
      justify={'space-between'}
      align="center"
      flexWrap="wrap"
    >
      <Link href="/" passHref paddingX={3}>
        <Box as="a">
          <LogoWordmarkRemarkCompany height={['30px', null, '40px']} />
        </Box>
      </Link>
      <HStack>
        <ButtonNextLink
          route="/login"
          variant="solid"
          size="lg"
          width="auto"
          bg={landingTheme.buttonOrange}
          color={landingTheme.primaryOrange}
        >
          Login
        </ButtonNextLink>
        <ButtonNextLink
          route={'/signup'}
          backgroundColor={landingTheme.primaryOrange}
          variant="solid"
          color="scheme.textPrimaryInverse"
          _hover={{ opacity: 0.8 }}
          _active={{ opacity: 0.6 }}
          display={['none', 'inherit', 'inherit']}
          size="lg"
          width="auto"
        >
          {'Join the community'}
        </ButtonNextLink>
      </HStack>
    </Flex>
  </Flex>
);

MainHeader.propTypes = {};

const Footer = () => (
  <Flex as="header" bg="#000" justify="center" flexDir="column">
    <Box maxW={'1190px'} margin="auto" w="100%" px={4}>
      <Box padding={'56px 0 24px 0'}>
        <Link href="/" passHref>
          <Box as="a" mt={-1}>
            <Logo
              height={['25px', null, '35px']}
              color="scheme.textPrimaryInverse"
            />
          </Box>
        </Link>
      </Box>
      <HStack spacing="xl" marginTop={['md', null, 0]} mb={'30px'}>
        <Link passHref href="/about">
          <Text as="a" color="scheme.textPrimaryInverse">
            About
          </Text>
        </Link>
        <Link passHref href="/about#jobs">
          <Text as="a" color="scheme.textPrimaryInverse">
            Careers
          </Text>
        </Link>
        <Link passHref href="/about/privacy">
          <Text as="a" color="scheme.textPrimaryInverse">
            Privacy Policy
          </Text>
        </Link>
      </HStack>
      <HStack marginTop={['md', null, 0]} mb={'94px'}>
        <Text color="scheme.textPrimaryInverse">
          © Outdoorly. All Rights Reserved
        </Text>
      </HStack>
    </Box>
  </Flex>
);

const HowItWorksCard = ({ image, header, text }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={fadeInVariant}
      style={{ alignSelf: 'stretch', flexBasis: '33.3%' }}
    >
      <Box
        margin={['12px 0px', '12px 0px', '12px 0px', '0 8px']}
        background="white"
        borderRadius="36px"
        padding={['16px', '16px', '16px', '32px']}
        boxShadow="0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)"
        h="100%"
      >
        <Center mb={4}>
          <NextImage layout="fixed" width="204px" height="160px" src={image} />
        </Center>
        <Heading
          fontSize={['xl', 'xl', 'xl', '2xl']}
          color={'scheme.textPrimary'}
          fontWeight={600}
          textAlign="center"
          mb={3}
        >
          {header}
        </Heading>
        <Text fontSize="md" color={'scheme.textPrimary'} textAlign="center">
          {text}
        </Text>
      </Box>
    </motion.div>
  );
};

HowItWorksCard.propTypes = {
  header: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
};

const HowItWorks = () => (
  <Box backgroundColor={landingTheme.primaryOrange}>
    <Heading
      as="h2"
      py={4}
      fontFamily="aesthet-nova, serif"
      fontWeight={900}
      color={'scheme.textPrimaryInverse'}
      fontSize={['3xl', '3xl', '3xl', '48px']}
      mt={[4, 4, 4, 8]}
      textAlign="center"
    >
      How it works
    </Heading>
    <Center>
      <Text
        maxW="700px"
        mx="15px"
        color="scheme.textPrimaryInverse"
        fontSize="18px"
        lineHeight="32px"
        textAlign="center"
        mb="28px"
      >
        Our deals are only available to those who are professionally employed in
        the outdoor industry. We carefully curate our platform to ensure that it
        is only accessible to those who are truly dedicated to their craft.
      </Text>
    </Center>
    <Flex
      direction={['column', 'column', 'column', 'row']}
      align="center"
      px={4}
      pt={5}
      pb={20}
      maxW="1158px"
      m="auto"
    >
      <HowItWorksCard
        header={"You're a leader"}
        text={
          "You may not be an olympian, but to your clients, friends, and family, you might as well be. You've gotten your certifications, you've had extensive training, and you love what you do but more than anything, you live to share the mountains with other tree-hugging dirt worshippers."
        }
        image={'/images/landing/illustration-questions.svg'}
      />
      <HowItWorksCard
        header={'You know gear'}
        text={
          'Your friends and family rely on you for recommendations. You get excited to vent through your pit zips and cringe when you see people wearing down jackets in the rain. You research your purchases and take your gear choices seriously since your comfort, safety, and style depend on it.'
        }
        image={'/images/landing/illustration-expert.svg'}
      />
      <HowItWorksCard
        header={'You deserve deals'}
        text={
          "You are the front line of the outdoor industry. Brands want you to put their gear through the wringer. We offer you the privilege of getting discounts on your favorite brands. This is our way of saying thanks. We're here for all of your gear needs, because you should be spending time outside, not online."
        }
        image={'/images/landing/illustration-mountain.svg'}
      />
    </Flex>
  </Box>
);

HowItWorks.propTypes = {};

const QualCard = ({ image, header, items }) => (
  <Flex
    backgroundColor="scheme.bgPrimary"
    as="li"
    direction={['column', null, 'row', 'column']}
    align="center"
    borderRadius="36px"
    boxShadow="0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)"
    p={'32px'}
    h="100%"
  >
    <Box
      sx={{
        background: `url(${image}) no-repeat 50% 50%`,
        height: '130px',
        minW: '206px',
        content: `""`,
        display: 'block',
      }}
    />
    <Stack spacing="xl" ml={[0, null, 10, 0]} p="0 10px">
      <Heading
        as="h3"
        size="md"
        textAlign={['center', null, 'left', 'center']}
        fontWeight="500"
      >
        {header}
      </Heading>
      <Stack as="ul" pl={3} spacing="sm">
        {items.map((item, index) => (
          <Text
            size="md"
            key={index}
            color="scheme.textSecondary"
            textAlign="left"
            as="li"
          >
            {item}
          </Text>
        ))}
      </Stack>
    </Stack>
  </Flex>
);

QualCard.propTypes = {
  header: PropTypes.string,
  image: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

const DoYouQualify = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <Flex
      backgroundColor={landingTheme.primaryYellow}
      direction="column"
      align="center"
      px={4}
      pt={10}
      pb={16}
    >
      <Heading
        as="h2"
        p={4}
        fontFamily="aesthet-nova, serif"
        fontWeight={900}
        fontSize={['3xl', '3xl', '3xl', '48px']}
        color="black"
      >
        Do you qualify?
      </Heading>
      <Text
        textAlign="center"
        fontSize="18px"
        maxW="730px"
        color="scheme.textSecondary"
      >
        Our deals are specially formulated for those who are professionally
        employed in the outdoor industry. That means we only let true
        professionals onto our platform.
      </Text>
      <Grid
        templateColumns={['1fr', null, null, '1fr 1fr 1fr']}
        as="ul"
        gap={6}
        mt={10}
        maxW="1110px"
      >
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={fadeInVerticalVariant}
        >
          <QualCard
            header="Guides"
            items={[
              'Backcountry Ski & Snowboard ',
              'Climbing & Mountaineering ',
              'Adventure',
              'River, Sea Kayak & Paddling',
              'Hunting & Fishing',
            ]}
            image="/images/landing/qualify-guides.svg"
          />
        </motion.div>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={{
            ...fadeInVerticalVariant,
            visible: {
              ...fadeInVerticalVariant.visible,
              transition: {
                ...fadeInVerticalVariant.visible.transition,
                delay: 0.1,
              },
            },
          }}
        >
          <QualCard
            header="Rescue Workers"
            items={[
              'Avalanche & Snow Safety Pros',
              'Search & Rescue Professionals',
              'Park Rangers',
              'Ski Patrollers',
              'Law Enforcement & Military',
            ]}
            image="/images/landing/qualify-rescue.svg"
          />
        </motion.div>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={{
            ...fadeInVerticalVariant,
            visible: {
              ...fadeInVerticalVariant.visible,
              transition: {
                ...fadeInVerticalVariant.visible.transition,
                delay: 0.2,
              },
            },
          }}
        >
          <QualCard
            header="Educators"
            items={[
              'Outdoor & Wilderness Educators',
              'PSIA/AASI Instructors',
              'Ski & Snowboard Coaches',
              'Cycling Coaches',
              'Fitness, Yoga & Pilates Instructors',
            ]}
            image="/images/landing/qualify-educators.svg"
          />
        </motion.div>
      </Grid>
    </Flex>
  );
};

export const LandingPage = () => {
  return (
    <>
      <Head>
        <link href="https://use.typekit.net/lda4gqx.css" rel="stylesheet" />
      </Head>
      <Flex direction="column" maxW="100vw" overflow="hidden">
        <LandingHeader />
        <MainHeader />
        <LandingHero />
        <HowItWorks />
        <ExpertCommunity />
        <DoYouQualify />
        <LandingCTA />
        <LandingMediaSummary
          orientation="row"
          heading="Are you a true pro?"
          body="Are you the one your friends go to for advice on what to buy next? Do your clients always envy your goggles? You're a gear trendsetter. Our platform was built for people just like you."
          backgroundColor={landingTheme.secondaryYellow}
          image={'/images/landing/summary-expert.png'}
          link={
            <Box alignSelf={['center', 'center', 'flex-start']}>
              <ButtonNextLink
                variant="solid"
                background={landingTheme.primaryOrange}
                color="scheme.textPrimaryInverse"
                _hover={{ opacity: 0.8 }}
                _active={{ opacity: 0.6 }}
                route="/signup"
                mt={5}
                size="lg"
                width="auto"
              >
                {'Get started'}
              </ButtonNextLink>
            </Box>
          }
        />

        <ExpertList />
        <LandingQuote />
        <Footer />
      </Flex>
    </>
  );
};
