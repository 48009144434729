import PropTypes from 'prop-types';
import { Box, Flex, VStack, Text } from '@chakra-ui/layout';
import { fadeInVariant, landingTheme } from './theme';
import NextImage from 'next/image';
import { useAnimation, motion } from 'framer-motion';

export const LandingMediaSummary = ({
  orientation,
  backgroundColor,
  heading,
  body,
  image,
  link,
}) => {
  const controls = useAnimation();
  return (
    <Box
      w="100%"
      backgroundColor={landingTheme.primaryYellow}
      px="16px"
      py={[0, 0, '64px']}
    >
      <Flex
        flexDir={['column-reverse', 'column-reverse', orientation]}
        maxW="1100px"
        margin="auto"
        alignItems="center"
      >
        <VStack
          flexBasis="50%"
          justifyContent="center"
          ml={[0, 0, orientation === 'row-reverse' ? 12 : 0]}
          mr={[0, 0, orientation === 'row-reverse' ? 0 : 12]}
          mb={[10, 10, 0]}
        >
          <Text
            fontSize={['3xl', '3xl', '3xl', '40px']}
            lineHeight={['40px', '40px', '40px', '54px']}
            fontWeight={900}
            fontFamily="aesthet-nova, serif"
            my={8}
            textAlign={['center', 'center', 'left']}
            width="100%"
          >
            {heading}
          </Text>
          <Text
            fontSize={'18px'}
            lineHeight={'32px'}
            color="scheme.textSecondary"
            textAlign={['center', 'center', 'left']}
          >
            {body}
          </Text>
          {link ? link : null}
        </VStack>
        <Box
          w={['335px', '335px', '425px', '540px']}
          h={['335px', '335px', '425px', '540px']}
          backgroundColor={backgroundColor}
          borderRadius="xl"
          pos="relative"
          mt={['24px', '24px', 0]}
        >
          <Box
            w={['300px', '300px', '340px', '450px']}
            h={['300px', '300px', '340px', '450px']}
            pos="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <motion.div
              animate={controls}
              initial="hidden"
              variants={fadeInVariant}
            >
              <NextImage
                onLoadingComplete={() => controls.start('visible')}
                src={image}
                layout="fill"
                objectPosition="center"
                objectFit="contain"
              />
            </motion.div>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

LandingMediaSummary.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  orientation: PropTypes.string,
};
